import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "buzzword-soup-explained"
    }}>{`Buzzword soup explained`}</h1>
    <p>{`We're going to use some buzzwords in this course. Hell, we've used some already.
Most will get a thorough explanation further on, but let me give you a quick
rundown.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Babel`}</strong>{`, a JavaScript transpiler that makes your code work on all browsers.`}</li>
      <li parentName="ul"><strong parentName="li">{`ECMAScript2015,16,17,18`}</strong>{`, official names for JavaScript as new features
come out every summer`}</li>
      <li parentName="ul"><strong parentName="li">{`ES5`}</strong>{`, any JavaScript features that existed before June 2015`}</li>
      <li parentName="ul"><strong parentName="li">{`ES6`}</strong>{` or `}<strong parentName="li">{`ES6+`}</strong>{`, a common name for JavaScript as it exists past June 2015`}</li>
      <li parentName="ul"><strong parentName="li">{`fat arrows`}</strong>{`, a new way to define functions in ES6 (`}<inlineCode parentName="li">{`=>`}</inlineCode>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Git`}</strong>{`, a version control system. It's pretty popular, you probably know it
😃`}</li>
      <li parentName="ul"><strong parentName="li">{`H1B`}</strong>{`, a popular type of work visa in the United States`}</li>
      <li parentName="ul"><strong parentName="li">{`JSX`}</strong>{`, a language/syntax that lets you use `}<inlineCode parentName="li">{`<a href="/example">`}</inlineCode>{` as a
native part of JavaScript`}</li>
      <li parentName="ul"><strong parentName="li">{`Mustache`}</strong>{`, a popular way to write HTML templates for JavaScript code. Uses
`}<inlineCode parentName="li">{`{{ ... }}`}</inlineCode>{` syntax.`}</li>
      <li parentName="ul"><strong parentName="li">{`npm`}</strong>{`, most popular package manager for JavaScript libraries`}</li>
      <li parentName="ul"><strong parentName="li">{`props`}</strong>{`, component properties set when rendering`}</li>
      <li parentName="ul"><strong parentName="li">{`state`}</strong>{`, a local dictionary of values available in most components`}</li>
      <li parentName="ul"><strong parentName="li">{`functional components`}</strong>{`, React components expressed as pure functions that
take props and return markup`}</li>
      <li parentName="ul"><strong parentName="li">{`Webpack`}</strong>{`, a module packager for JavaScript. Makes it more convenient to
organize code into multiple files. Provides cool plugins.`}</li>
    </ul>
    <h2 {...{
      "id": "jsx"
    }}>{`JSX`}</h2>
    <p>{`Our components are going to use JSX, a JavaScript syntax extension that lets us
treat XML-like data as normal code. You can use React without JSX, but JSX
makes React’s full power easier to use.`}</p>
    <p>{`The gist of JSX is that we can use any XML-like string just like it was part of
JavaScript. No Mustache or messy string concatenation necessary. Your functions
can return HTML, SVG, or XML.`}</p>
    <p>{`For instance, code that renders one of our first examples – a color swatch
– looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`ReactDOM.render(<Colors width="400" />, document.getElementById("svg"))
`}</code></pre>
    <p>{`Which compiles to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`ReactDOM.render(
  React.createElement(Colors, { width: "400" }),
  document.querySelectorAll("svg")[0]
)
`}</code></pre>
    <p>{`HTML code translates to `}<inlineCode parentName="p">{`React.createElement`}</inlineCode>{` calls with attributes translated
into a property dictionary. The beauty of this approach is two-pronged: you can
use React components as if they were HTML tags, and HTML attributes can be
anything.`}</p>
    <p>{`You’ll see that anything from a simple value to a function or an object works
equally well.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      